var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-wrapper auth-v2"
  }, [_c('b-row', {
    staticClass: "auth-inner m-0"
  }, [_c('b-link', {
    staticClass: "brand-logo"
  }, [_c('h2', {
    staticClass: "brand-text text-primary ml-1"
  }, [_vm._v(" Akun - Ubah Password ")])]), _c('b-col', {
    staticClass: "d-none d-lg-flex align-items-center p-5",
    attrs: {
      "lg": "8"
    }
  }, [_c('div', {
    staticClass: "w-100 d-lg-flex align-items-center justify-content-center px-5"
  }, [_c('b-img', {
    attrs: {
      "src": _vm.imgUrl,
      "fluid": "",
      "alt": "Register V2"
    }
  })], 1)]), _c('b-col', {
    staticClass: "d-flex align-items-center auth-bg px-2 p-lg-5",
    attrs: {
      "lg": "4"
    }
  }, [_c('b-col', {
    staticClass: "px-xl-2 mx-auto",
    attrs: {
      "sm": "8",
      "md": "6",
      "lg": "12"
    }
  }, [_c('b-card-title', {
    staticClass: "font-weight-bold mb-1",
    attrs: {
      "title-tag": "h2"
    }
  }, [_vm._v(" Ubah Password 🔒 ")]), _c('b-card-text', {
    staticClass: "mb-2"
  }, [_vm._v(" Membuat password baru, sebaiknya berbeda dengan sebelumnya. ")]), _c('validation-observer', {
    ref: "simpleRules"
  }, [_c('b-form', {
    staticClass: "auth-reset-password-form mt-2",
    attrs: {
      "method": "POST"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationForm.apply(null, arguments);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Password Baru",
      "label-for": "reset-password-new"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Password",
      "vid": "Password",
      "rules": "required|password"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge",
          class: errors.length > 0 ? 'is-invalid' : null
        }, [_c('b-form-input', {
          staticClass: "form-control-merge",
          attrs: {
            "id": "reset-password-new",
            "type": _vm.password1FieldType,
            "state": errors.length > 0 ? false : null,
            "name": "reset-password-new",
            "placeholder": "············"
          },
          model: {
            value: _vm.password,
            callback: function callback($$v) {
              _vm.password = $$v;
            },
            expression: "password"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": _vm.password1ToggleIcon
          },
          on: {
            "click": _vm.togglePassword1Visibility
          }
        })], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.newPassword(errors[0])))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label-for": "reset-password-confirm",
      "label": "Confirm Password"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Confirm Password",
      "rules": "required|confirmed:Password"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge",
          class: errors.length > 0 ? 'is-invalid' : null
        }, [_c('b-form-input', {
          staticClass: "form-control-merge",
          attrs: {
            "id": "reset-password-confirm",
            "type": _vm.password2FieldType,
            "state": errors.length > 0 ? false : null,
            "name": "reset-password-confirm",
            "placeholder": "············"
          },
          model: {
            value: _vm.cPassword,
            callback: function callback($$v) {
              _vm.cPassword = $$v;
            },
            expression: "cPassword"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": _vm.password2ToggleIcon
          },
          on: {
            "click": _vm.togglePassword2Visibility
          }
        })], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-button', {
    attrs: {
      "block": "",
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Terapkan Password Baru ")])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }